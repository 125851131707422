<template>
    <header id="job-header" v-if="ogloszenie">
        <div>
        <span>Oferta</span>
        <h1>{{ ogloszenie.tytul }}</h1>
        </div>
        <img :src="ogloszenie.grafika_2" class="job-header-img">
    </header>
    <section id="sekcja-oferta-pracy" v-if="ogloszenie && ogloszenie.klient && ogloszenie.pracodawca">
        
        <h2>Informacje</h2>


        <table class="informacja-o-ofercie">
            <tr>
                <td>Stanowisko:</td>
                <td>{{ ogloszenie.stanowisko }}</td>
            </tr>
            <tr>
                <td>Rekrutacja do:</td>
                <td>{{ formattedDate(ogloszenie.data_waznosci) }}</td>
            </tr>

            <tr>
                <td>Stawka:</td>
                <td>{{ ogloszenie.stawka }} {{ ogloszenie.waluta }}<span v-if="ogloszenie.typ_wyplaty=='Stawka godzinowa'">/h</span><span v-if="ogloszenie.typ_wyplaty=='Miesięczna'">/m-c</span> brutto</td>
            </tr>

            <tr>
                <td>Zatrudnia:</td>
                <td>{{ (ogloszenie.pracodawca.nazwa) }}</td>
            </tr>

            <tr v-if="ogloszenie.klient.nazwa">
                <td>Klient:</td>
                <td>{{ (ogloszenie.klient.nazwa) }}</td>
            </tr>

            <tr>
                <td>Lokalizacja:</td>
                <td>{{ ogloszenie.kraj }}, {{ ogloszenie.miasto }}</td>
            </tr>

            <tr>
                <td>Tryb pracy:</td>
                <td>{{ ogloszenie.tryb_pracy }}</td>
            </tr>

            <tr>
                <td>Umowa:</td>
                <td>{{ ogloszenie.umowa }}</td>
            </tr>

            <tr>
                <td>ID ogłoszenia:</td>
                <td>#{{ ogloszenie.id }}</td>
            </tr>
        
        </table>

        <button class="aplikuj">
        Aplikuj
        </button>
       
        <Markdown class="opis-pracodawcy" :source="ogloszenie.tresc" :brakes="true"/>

        <h2 class="opcje-oferty">Za co będziesz odpowiadać?</h2>
        <ul v-for="o in ogloszenie.obowiazki" :key="o.id">
            <li>{{ o }}</li>
        </ul>

        <h2 class="opcje-oferty">Co oferujemy?</h2>
        <ul v-for="o in ogloszenie.oferujemy" :key="o.id">
            <li>{{ o }}</li>
        </ul>

        <h2 class="opcje-oferty">Co jest wymagane na tym stanowisku?</h2>
        <ul v-for="o in ogloszenie.wymagania_obowiazkowe" :key="o.id">
            <li>{{ o }}</li>
        </ul>

        <h2 class="opcje-oferty">Mile widziane doświadczenie:</h2>
        <ul v-for="o in ogloszenie.wymagania_dodatkowe" :key="o.id">
            <li>{{ o }}</li>
        </ul>
        
        <Markdown class="opis-pracodawcy" :source="ogloszenie.pracodawca.opis" :brakes="true"/>
 

        <div v-if="loading" class="loading">Loading...</div>

        <div v-if="error" class="error">{{ error }}</div>

    </section>

</template>

<script>
import Markdown from 'vue3-markdown-it';

export default {
        name: 'Job',
        props: ['jobid'],
        components: {
            Markdown
        },

        data() {
            return{
                ogloszenie: [],
                thisid: this.jobid,
                
                loading: false,
                error: null,
            }
        },

        async mounted() {
            // Zaptanie do API
            let apiHeadersList = {
                "Accept": "*/*",
                "Content-Type": "application/json"
                };

            let apiBodyContent = JSON.stringify({
                    "klucz": process.env.VUE_APP_JOB_KEY,
                    "id": this.thisid
                });

            let apiUrl = process.env.VUE_APP_OGLOSZENIE;

            let apiMethod = "POST";

            this.fetchData(apiUrl, apiMethod, apiHeadersList, apiBodyContent);
        },

        methods: {

            async fetchData(url, method, headers, body) {
                this.error = null;
                this.ogloszenie = [];
                this.loading = true;

                try {
                const response = await fetch(url, {
                    method: method,
                    headers: headers,
                    body: body
                });

                const data = await response.json();

                
                this.ogloszenie =  data.ogloszenie
                } catch (err) {
                this.error = err.toString();
                } finally {
                this.loading = false;
                }
            },
            
            formattedDate(dateString) {
                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                return new Date(dateString).toLocaleDateString('pl-PL', options);
            },
        }
        
    }


</script>

<style scoped>
@import './Job.css';

</style>