<template>
    <div id="training-center-header">
      <div class="wrap training-center">
        <h1 class="training-center">Welding and Insulation Training Center</h1>
      </div>
    </div>

<WeldingPart/>
<WeldingMachines/>
<InsulationPart/>
<InsulationTraining/>
<Theoretical/>
<LocationPart/>
<PIFS/>
<Footer/>
</template>

<script>
import Footer from '@/components/Footer/Footer.vue';
import LocationPart from './LocationPart/LocationPart.vue';
import WeldingMachines from './WeldingMachines/WeldingMachines.vue';
import WeldingPart from './WeldingPart/WeldingPart.vue';
import InsulationPart from './InsulationPart/InsulationPart.vue';
import InsulationTraining from './InsulationTraining/InsulationTraining.vue';
import Theoretical from './Theoretical/Theoretical.vue';
import PIFS from './PIFS/PIFS.vue';

export default {
        name: 'TrainingCenter',
        components: {
          WeldingPart,
          WeldingMachines,
          InsulationPart,
          InsulationTraining,
          LocationPart,
          Theoretical,
          PIFS,
          Footer
        },

data() {
    return {
      items: [],
      dataReady: false,
    };
  },

  }

</script>

<style>
@import './TrainingCenter.css'
</style>