<template>
    <section id="pifs-section">
        <div class="wrap pifs">
            <div class="pifs-content">
                <div class="pifs-img">
                    <img class="pifs-logo" :src="images.pifsLogo" crossorigin=""/>
                </div>
                <div class="pifs-info">
                    <p>SEC Operations Sp. z o.o. operator of Insulation and Welding Training Center is active member of PIFS.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'PIFS',

    data(){
            return{
                images: {
                    pifsLogo: require('@/assets/Clients/Logo PIFS.svg')
                },
            }
    
        },

    created () {
        window.addEventListener('scroll', this.onScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll)
    },

    methods: {
        counters(){
        const figures = document.querySelectorAll('.figure');
        

        figures.forEach(figure=> {
            const updateCount = () => {
                const target = figure.getAttribute('data-target');

                console.log(target);
            }
            updateCount();
        })

        }

    }
}
</script>

<style src='./PIFS.css'>

</style>
